<template>
    <nav class="navbar navbar-expand-md fixed-top navbar-dark bg-dark py-md-2 shadow-sm">
        <button class="navbar-toggler" type="button" onclick="$('#sidebar').toggleClass('show')">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="col col-md-auto d-flex">
            <router-link class="navbar-brand" to="/student">인코딩</router-link>
        </div>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse align-items-stretch" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item px-2" :class="{active:pageName == 'student'}">
                    <router-link class="nav-link" to="/student">원생 <span class="sr-only" v-show="pageName == 'student'">(current)</span></router-link>
                </li>
                <li class="nav-item px-2" :class="{active:pageName == 'attendance'}">
                    <router-link class="nav-link" to="/attendance">출석 <span class="sr-only" v-show="pageName == 'attendance'">(current)</span></router-link>
                </li>
                <li class="nav-item px-2" :class="{active:pageName == 'calendar' || pageName == 'courseInfo'}">
                    <router-link class="nav-link" to="/calendar">수업 <span class="sr-only" v-show="pageName == 'course'">(current)</span></router-link>
                </li>
                <li class="nav-item px-2" :class="{active:pageName == 'message'}">
                    <router-link class="nav-link" to="/message">문자 <span class="sr-only" v-show="pageName == 'message'">(current)</span></router-link>
                </li>
                <li class="nav-item px-2" :class="{active:pageName == 'consult'}">
                    <router-link class="nav-link" to="/consult">상담 <span class="sr-only" v-show="pageName == 'consult'">(current)</span></router-link>
                </li>
                <li class="nav-item px-2" :class="{active:pageName == 'evaluation' || pageName == 'analysis'}">
                    <router-link class="nav-link" to="/evaluation">평가 <span class="sr-only" v-show="pageName == 'evaluation'">(current)</span></router-link>
                </li>
                <li class="nav-item px-2" :class="{active:pageName == 'bus'}">
                    <router-link class="nav-link" to="/bus">버스 <span class="sr-only" v-show="pageName == 'bus'">(current)</span></router-link>
                </li>
                <li class="nav-item px-2" :class="{active:pageName == 'tuition'}">
                    <router-link class="nav-link" to="/tuition">원비 <span class="sr-only" v-show="pageName == 'tuition'">(current)</span></router-link>
                </li>
            </ul>

            <form v-if="!isLogedIn" class="col-12 col-md-auto my-2 my-md-0 px-0 d-flex align-items-center" @submit.prevent="login">
                <div class="form-row mx-0">
                    <div class="col-sm col-12 mb-2 mb-sm-0">
                        <input class="form-control form-control-sm" name="username" type="text" placeholder="ID" 
                            v-model.trim="$v.form.username.$model"
                            :class="{'is-invalid':$v.form.username.$anyError}">
                        <div class="invalid-tooltip">
                            아이디를 입력해주세요
                        </div>
                    </div>
                    <div class="col-sm col-12 mb-2 mb-sm-0">
                        <input class="form-control form-control-sm" name="password" type="password" placeholder="PW" 
                            v-model.trim="$v.form.password.$model"
                            :class="{'is-invalid':$v.form.password.$anyError}">
                        <div class="invalid-tooltip">
                            비밀번호를 입력해주세요
                        </div>
                    </div>
                    <div class="col-sm-auto col-12">
                        <button class="btn btn-sm btn-success px-3" type="submit" :disabled="isRequesting">
                            <span :class="{'d-none':isRequesting==true}">로그인</span>
                            <div class="spinner-border spinner-border-sm text-light" role="status" :class="{'d-none':isRequesting!=true}">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
            </form>

            <form v-if="isLogedIn" id="logoutForm" class="col-12 col-md-auto my-2 my-md-0 px-0 d-flex align-items-center" @submit.prevent="logout">
                <div class="form-row mx-0">
                    <div class="col-auto text-white pr-1">
                        {{username}}님 반갑습니다.
                    </div>
                    <div class="col-auto pl-1">
                        <button class="btn btn-sm btn-danger px-3 btn-block h-100" type="submit" :disabled="isRequesting">
                            <span :class="{'d-none':isRequesting==true}">로그아웃</span>
                            <div class="spinner-border spinner-border-sm text-light" role="status" :class="{'d-none':isRequesting!=true}">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </nav>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default {
    name:"navBar",
    components:{},
    data:function(){
        return {
            form:{
                username:"",
                password:""
            },
            isRequesting:false
        }
    },
    validations:{
        form:{
            username:{ required },
            password:{ required }
        }
    },
    computed:{
        pageName(){
            return this.$route.name;
        },
        ...mapGetters('user', [
            'isLogedIn'
        ]),
        ...mapState('user', [
            'username'
        ])
    },
    watch:{
        pageName(val, oldVal){
            if(val !== oldVal) $('#navbarSupportedContent').collapse('hide');
        }
    },
    methods:{
        login:function(){
            this.$v.form.$touch();
            if(this.$v.form.$anyError){
                this.$toast.warning({
                    title:"정보를 입력해주세요"
                })
                return;
            }

            this.isRequesting = true;
            this.$store.dispatch('user/login', {
                username: this.form.username,
                password: this.form.password
            })
            .then(()=>{
                location.reload();
            }, (e)=>{
                this.isRequesting = false;
                this.$toast.error({
                    title:"로그인 에러"
                })
            })
        },
        logout:function(){
            this.isRequesting = true;
            this.$store.dispatch('user/logout')
            .then(()=>{
                location.reload();
            }, (e)=>{
                this.isRequesting = false;
            });
        }
    }
}
</script>