import iziToast from 'izitoast';
import axios from '../../js/axiosWrapper';

const initialParent = () => ({
    name:'',
    phoneNumber:'',
    _id:''
});

const initialState = () => ({
    list:[],
    selectedParent:initialParent(),
    selectedParents:[]
})

const getters = {
    map: (state) => state.list.reduce((map, el) => {
        map[el._id] = el.name;
        return map
    }, {})
}

const actions = {
    getList({ commit }){
        return axios.get('/parent/list')
        .then(({ data, status })=>{
            if(data.success){
                commit('list', data.data);
                return data.data
            } else{
                throw new Error('학부모 목록을 가져오는데 실패했습니다.');
            }
        })
    },
    add({ commit }, { name, phoneNumber }){
        return axios.post("/parent", { name, phoneNumber })
        .then(({ data, status})=>{
            if(data.success){
                commit('add', data.data);
                commit('select', data.data);
                iziToast.success({title:"성공적으로 추가했습니다"});
            } else{
                iziToast.error({title:"추가에 실패했습니다."});
                throw new Error('학부모 추가에 실패했습니다.');
            }
        }, (e)=>_)
    },
    update({ commit, dispatch }, { _id, name, phoneNumber }){
        return axios.put(`/parent/${_id}`, { name, phoneNumber })
        .then(({ data, status})=>{
            if(data.success){
                iziToast.success({title:"성공적으로 수정했습니다"});
                return dispatch('getList').then((result)=>{
                    const parent = result.find(el => el._id == _id)
                    commit("select", parent)
                    return result
                })
            } else{
                iziToast.error({title:"수정에 실패했습니다."});
                throw new Error('학부모 수정에 실패했습니다.');
            }
        }, (e)=>_)
    },
    delete({ commit }, parent){
        return axios.delete(`/parent/${parent._id}`)
        .then(({ data, status})=>{
            if(data.success){
                commit('delete', parent._id);
                iziToast.success({title:"성공적으로 삭제했습니다"});
            } else{
                iziToast.error({title:"삭제에 실패했습니다"});
                throw new Error('학부모 삭제에 실패했습니다.');
            }
        }, (e)=>_)
    },
    select({ commit, dispatch }, parentId){
        return axios.get(`/parent/${parentId}`)
        .then(({status, data}) => {
            if(data.success){
                commit('select', data.data);
                return data.data;
            } else{
                throw new Error("학부모 정보를 받아오는데 실패했습니다.");
            }
        })
    }
}

const mutations = {
    list(state, list){
        state.list = list;
    },
    select(state, parent){
        state.selectedParent = parent;
        state.selectedParents.push(parent);
    },
    add(state, parent){
        state.list.push(parent);
    },
    delete(state, parentId){
        let idx = state.list.findIndex((parent)=>parent._id == parentId);
        if(idx !== -1){
            state.list.splice(idx, 1);
            if(parentId == state.selectedParent._id)
                state.selectedParent = initialParent();
        }
    },
    update(state, parent){
        let idx = state.list.findIndex((p)=>p._id == parent._id);
        if(idx !== -1){
            state.list.splice(idx, 1, parent);
            if(parentId == state.selectedParent._id)
                state.selectedParent = parent;
        }
    },
    newParent(state){
        state.selectedParent = initialParent();
    }
}

export default {
    namespaced: true,
    state : initialState,
    getters,
    actions,
    mutations
}