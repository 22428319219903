import Vue from 'vue'
import iziToast from 'izitoast';
import axios from '../../js/axiosWrapper';
import jsonMask from 'json-mask';

const initialState = () => ({
    payments:{},
})

const getters = {
    parentPayment : (state) => (parentId) => state.payments[parentId] || null
}

const actions = {
    getPayment({ commit }, { parentId }){
        return axios.get(`/payment/${parentId}`)
        .then(({ status, data : { success, data }})=>{
            if(success){
                commit('payment', { parentId, data });
                return data;
            }
        })
    },

    addPayment({ commit }, { parentId, date, amount, method, memo }){
        return axios.post(`/payment/${parentId}`, {
            date, amount, method, memo
        })
        .then(({status, data : { success, data }})=>{
            if(success){
                commit('payment', { parentId, data });
            }
        });
    },

    updatePayment({ commit }, { parentId, paymentId, date, amount, method, memo }){
        return axios.put(`/payment/${parentId}/${paymentId}`, {
            date, amount, method, memo
        })
        .then(({status, data : { success, data }})=>{
            if(success){
                commit('payment', { parentId, data });
            }
        });
    },

    destroyBill({ commit }, { parentId, paymentId }){
        return axios.delete(`/payment/${parentId}/${paymentId}/bill`)
        .then(({status, data : { success, data }})=>{
            if(success){
                commit('payment', { parentId, data });
            }
        });
    }
}

const mutations = {
    payment(state, { parentId, data }){
        if(data != null)
            data.payments.sort((a, b) => a.date < b.date ? -1 : 1);
        Vue.set(state.payments, parentId, data);
    }
}

export default {
    namespaced: true,
    state : initialState,
    getters,
    actions,
    mutations
}