import VueRouter from 'vue-router';

export default new VueRouter({
    routes:[{
        path: '/',
        redirect: '/attendance'
    },{
        path: '/student/:studentId?',
        name: "student",
        component: () => import(/* webpackChunkName: "student" */ '@/components/student/app'),
    },{
        path: '/attendance/:courseId?',
        name: "attendance",
        component: () => import(/* webpackChunkName: "attendance" */ '@/components/attendance/app'),
    },{
        path: '/calendar',
        name: "calendar",
        component: () => import(/* webpackChunkName: "calendar" */ '@/components/calendar/app'),
    },{
        path: '/course/:courseInfoId?',
        name: "courseInfo",
        component: () => import(/* webpackChunkName: "course" */ '@/components/course/app'),
    },{
        path: '/message',
        name: "message",
        component: () => import(/* webpackChunkName: "message" */ '@/components/message/app'),
    },{
        path: '/consult/:studentId?',
        name: "consult",
        component: () => import(/* webpackChunkName: "consult" */ '@/components/consult/app'),
    },{
        path: '/evaluation',
        name: "evaluation",
        component: () => import(/* webpackChunkName: "evaluation" */ '@/components/evaluation/app'),
    },{
        path: '/analysis/:studentId?',
        name: "analysis",
        component: () => import(/* webpackChunkName: "analysis" */ '@/components/analysis/app'),
    },{
        path: '/bus',
        name: "bus",
        component: () => import(/* webpackChunkName: "bus" */ '@/components/bus/app'),
    },{
        path: '/tuition',
        name: "tuition",
        component: () => import(/* webpackChunkName: "tuition" */ '@/components/tuition/app'),
    }]
})
