import moment from 'moment-timezone';
import iziToast from 'izitoast';
import axios from '../../js/axiosWrapper';
import jsonMask from 'json-mask';

const Masks = {
    "add": ["title", "teacherId", "subTeacherIds", "tuition", "status", "schedule", "startDate", "studentIds", "category", "templateId"].join(","),
    "update": ["title", "teacherId", "subTeacherIds", "tuition", "status", "schedule", "startDate", "endDate", "studentIds", "category", "templateId"].join(","),
}

const initialSchedule = () => ({day:0, hour:10, minute:0, length:120});

const initialCourseInfo = () => ({
    _id:'',
    title:'',
    category:"",
    templateId:"",
    schedule:[initialSchedule()],
    tuition:0,
    studentIds:[],
    teacherId:"",
    subTeacherIds: [],
    startDate:new Date(),
    endDate:new Date(0),
    status:"정상"
})

const initialCourse = () => ({
    _id:'',
    title:'',
    courseInfoId:'',
    teacherId:'',
    subTeacherIds: [],
    status:'정상',
    time:{
        start:new Date(),
        end:new Date()
    },
    isAdditional:false,
    attendances:[],
    category:'',
    templateId:'',
})

const initialState = () => ({
    studentCourses : [],
    teacherRecentCourses : [],
    teacherCurrentCourses : [],
    courseInfoList : [],
    selectedCourseInfo : initialCourseInfo(),
    selectedCourse : initialCourse(),
})

const getters = {
    
}

const actions = {
    getStudentCourse({ commit, store }, studentId){
        return axios.get(`/student/course/${studentId}`)
        .then(({ data, status })=>{
            if(data.success){
                commit('studentCourse', data.data);
            } else{
                throw new Error('학생 수업목록을 가져오는데 실패했습니다.');
            }
        })
    },
    select({ commit }, courseId){
        return axios.get(`/course/${courseId}`)
        .then(({status, data})=>{
            if(data.success){
                let course = data.data;
                course.time.start = new Date(course.time.start);
                course.time.end = new Date(course.time.end);
                commit('select', course);
                commit('update', course);
                commit('attendances/attendances', course.attendances, { root:true });
            }
        }, e=>_)
    },
    getRecentCourses({ commit, rootState }){
        let teacherId = rootState.user.teacherId;
        if(!teacherId) return Promise.reject(new Error('로그인해주세요'));

        var today = moment().set('hour', 0).add(1, "d").toDate();
        return axios.get(`/teacher/courseList/${teacherId}`,{
            params:{fromDate:new Date(0), toDate:today, count:100}
        })
        .then(({status, data})=>{
            if(data.success){
                commit('teacherRecentCourses', data.data);
            }
        }, e=>_);
    },
    getCurrentCourses({ commit, rootState, dispatch }){
        let teacherId = rootState.user.teacherId;
        if(!teacherId) return Promise.reject(new Error('로그인해주세요'));

        return axios.get(`/teacher/currentCourse/${teacherId}`)
        .then(({status, data})=>{
            if(data.success){
                commit('teacherCurrentCourses', data.data);
                if(data.data.length > 0){
                    dispatch('select', data.data[0]._id);
                }
            }
        })
    },
    getInfoList({ commit }){
        return axios.get("/courseInfo/list")
        .then(({ data, status })=>{
            if(data.success){
                let courseInfos = data.data;
                courseInfos.forEach((info) => {
                    info.startDate = new Date(info.startDate);
                    info.endDate = new Date(info.endDate);
                })
                commit('courseInfoList', courseInfos);
            }
        })
    },
    selectInfo({ commit }, courseInfoId){
        return axios.get(`/courseInfo/${courseInfoId}`)
        .then(({ data, status })=>{
            if(data.success){
                commit('selectInfo', data.data);
                commit('updateInfo', data.data);
            }
        })
    },

    addCourseInfo({ commit }, courseInfo){
        return axios.post("/courseInfo", jsonMask(courseInfo, Masks.add))
        .then(({ data, status })=>{
            if(data.success){
                iziToast.success({
                    title:"성공적으로 추가했습니다"
                })
                commit('addInfo', data.data);
                commit('selectInfo', data.data);
            }
        })
    },
    updateCourseInfo({ commit }, courseInfo){
        return axios.put(`/courseInfo/${courseInfo._id}`, jsonMask(courseInfo, Masks.update))
        .then(({ data, status })=>{
            if(data.success){
                iziToast.success({
                    title:"성공적으로 수정했습니다"
                })
                commit('updateInfo', data.data);
                commit('selectInfo', data.data);
            }
        })
    },
    deleteCourseInfo({ commit }, courseInfo){
        return axios.delete(`/courseInfo/${courseInfo._id}`)
        .then(({ data, status })=>{
            if(data.success){
                iziToast.success({
                    title:"성공적으로 삭제했습니다"
                })
                commit('deleteInfo', courseInfo._id);
                commit('newCourseInfo');
            }
        })
    }
}

const mutations = {
    resetStudentCourse(state){
        state.studentCourses = [];
    },
    studentCourse(state, courses){
        state.studentCourses = [...courses];
    },
    select(state, course){
        state.selectedCourse = course;
    },
    selectInfo(state, courseInfo){
        state.selectedCourseInfo = courseInfo;
    },
    update(state, course){
        let idx = state.studentCourses.findIndex(el => el._id == course._id);
        if(idx !== -1) state.studentCourses.splice(idx, 1, course);
        
        idx = state.teacherRecentCourses.findIndex(el => el._id == course._id);
        if(idx !== -1) state.teacherRecentCourses.splice(idx, 1, course);
    },
    updateInfo(state, courseInfo){
        let idx = state.courseInfoList.findIndex(el => el._id == courseInfo._id);
        if(idx !== -1) state.courseInfoList.splice(idx, 1, courseInfo);
    },
    addInfo(state, courseInfo){
        state.courseInfoList.push(courseInfo);
    },
    deleteInfo(state, courseInfoId){
        let idx = state.courseInfoList.findIndex(el => el._id == courseInfoId);
        if(idx !== -1) state.courseInfoList.splice(idx, 1);
    },
    teacherRecentCourses(state, courses){
        state.teacherRecentCourses = [...courses];
    },
    teacherCurrentCourses(state, courses){
        state.teacherCurrentCourses = [...courses];
    },
    courseInfoList(state, infoList){
        state.courseInfoList = [...infoList];
    },
    newCourse(state, time){
        let course = initialCourse();
        course.time.start = new Date(time.start);
        course.time.end = new Date(time.end);
        state.selectedCourse = course;
    },
    newCourseInfo(state){
        state.selectedCourseInfo = initialCourseInfo();
    },
}

export default {
    namespaced: true,
    state : initialState,
    getters,
    actions,
    mutations
}