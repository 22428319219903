import iziToast from 'izitoast';
import axios from '../../js/axiosWrapper';

const attendanceCount = 30;

const initialState = () => ({
    attendancePage: 1,
    studentAttendances: [],
    studentId: "",
    attendances: [], // selectedCourse의 attendances
    courseAttendances: [] //selectedCourseInfo의 attendances
})

const getters = {
    
}

const actions = {
    getCourseAttendance({ commit }, courseInfoId){
        return axios.get(`/course/attendanceListOf/${courseInfoId}`, {
            params:{fromDate:new Date(0), toDate:new Date(), count:50}
        })
        .then(({ data, status })=>{
            if(data.success){
                commit('courseAttendace', data.data);
            }
        })
    },
    getStudentAttendance({ commit, state }, { studentId, more }){
        if(studentId !== state.studentId) {
            commit('resetStudentAttendance');
            commit('studentId', studentId);
        } else{
            if(more) commit('nextPage');
            else commit('resetStudentAttendance');
        }

        return axios.get(`/student/attendance/${studentId}`,{
            params:{
                fromDate:new Date(0), 
                toDate:new Date(), 
                count:attendanceCount,
                page:state.attendancePage
            }
        })
        .then(({ data, status })=>{
            if(data.success){
                commit('studentAttendance', data.data);
            } else{
                throw new Error('학생 출석목록을 가져오는데 실패했습니다.');
            }
        })
    },
    update({ commit, dispatch }, { courseId, attendances }){
        return axios.put(`/attendance/${courseId}`, { attendances })
        .then(({status, data})=>{
            if(data.success){
                commit('update', data.data);
                dispatch('courses/select', courseId, { root:true });
                iziToast.success({ title:"성공적으로 변경하였습니다." })
            } else{
                throw new Error("출석 변경에 실패했습니다.");
            }
        })

    }
}

const mutations = {
    resetStudentAttendance(state){
        state.studentAttendances = [];
        state.attendancePage = 1;
    },
    resetCourseAttendance(state){
        state.courseAttendances = [];
    },
    studentAttendance(state, attendances){
        state.studentAttendances.push(...attendances);
    },
    nextPage(state){
        state.attendancePage ++;
    },
    update(state, { _id: courseId, attendances }){
        let idx = state.studentAttendances.findIndex(course => course._id == courseId);
        if(idx !== -1){
            let course = state.studentAttendances[idx];
            for(let attendance of attendances){
                let idx2 = course.attendances.findIndex(att => att.studentId == attendance.studentId);
                if(idx2 !== -1){
                    course.attendances.splice(idx2, 1, attendance);
                }
            }
        }

        idx = state.courseAttendances.findIndex(course => course._id == courseId);
        if(idx !== -1){
            let course = state.courseAttendances[idx];
            for(let attendance of attendances){
                let idx2 = course.attendances.findIndex(att => att.studentId == attendance.studentId);
                if(idx2 !== -1){
                    course.attendances.splice(idx2, 1, attendance);
                }
            }
        }
    },
    studentId(state, studentId){
        state.studentId = studentId;
    },
    attendances(state, attendances){
        state.attendances = [...attendances];
    },
    courseAttendace(state, attendances){
        state.courseAttendances = [...attendances];
    }
}

export default {
    namespaced: true,
    state : initialState,
    getters,
    actions,
    mutations
}