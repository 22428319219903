import iziToast from 'izitoast';
import axios from '../../js/axiosWrapper';
import jsonMask from 'json-mask';

const Masks = {
    "add": ["name", "phoneNumber", "parentId", "school", "grade", "inDate", "outDate", "status", "busLocation", "busStatus"].join(","),
    "update": ["name", "phoneNumber", "parentId", "school", "grade", "inDate", "outDate", "status", "busLocation", "busStatus"].join(","),
}

const initialStudent = () => ({
    _id:'',
    name:'',
    grade:'',
    school:'',
    phoneNumber:'',
    parentId:'',
    busStatus:'',
    busLocation:'',
    status:'',
    inDate:new Date(),
    outDate:new Date(0)
});

const initialState = () => ({
    list:[],
    selectedStudent:initialStudent()
})

const getters = {
    map(state){
        return state.list.reduce((obj, el) => {
            obj[el._id] = el.name;
            return obj;
        }, {})
    }
}

const actions = {
    getList({ commit }){
        return axios.get('/student/list')
        .then(({ data, status })=>{
            if(data.success){
                commit('list', data.data);
            } else{
                throw new Error('학생 목록을 가져오는데 실패했습니다.');
            }
        })
    },
    add({ commit }, student){
        return axios.post("/student", jsonMask(student, Masks.add))
        .then(({ data, status})=>{
            if(data.success){
                commit('add', data.data);
                commit('select', data.data);
                iziToast.success({title:"성공적으로 추가했습니다"});
            } else{
                iziToast.error({title:"추가에 실패했습니다."});
                throw new Error('학생 추가에 실패했습니다.');
            }
        }, (e)=>_)
    },
    update({ commit }, student){
        return axios.put(`/student/${student._id}`, jsonMask(student, Masks.update))
        .then(({ data, status})=>{
            if(data.success){
                commit('update', {
                    studentId:student._id,
                    student:data.data
                });
                iziToast.success({title:"성공적으로 수정했습니다"});
            } else{
                iziToast.error({title:"수정에 실패했습니다."});
                throw new Error('학생 수정에 실패했습니다.');
            }
        }, (e)=>_)
    },
    delete({ commit }, student){
        return axios.delete(`/student/${student._id}`)
        .then(({ data, status})=>{
            if(data.success){
                commit('delete', student._id);
                iziToast.success({title:"성공적으로 삭제했습니다"});
            } else{
                iziToast.error({title:"삭제에 실패했습니다"});
                throw new Error('학생 삭제에 실패했습니다.');
            }
        }, (e)=>_)
    },
    select({ commit, dispatch, state }, studentId){
        return axios.get(`/student/${studentId}`)
        .then(({status, data}) => {
            if(data.success){
                commit('select', data.data);
                dispatch('parents/select', state.selectedStudent.parentId, { root:true });
            }
        })
    },
    addMemo({ commit }, {studentId, memo}){
        return axios.post(`/student/memo/${studentId}`, {memo})
        .then(({status, data})=>{
            if(data.success){
                iziToast.success({ title:"성공적으로 추가했습니다." })
                commit('addMemo', {
                    studentId, memo:data.data
                })
            }
        }, e=>_)
    },
    updateMemo({ commit }, {studentId, memo}){
        return axios.put(`/student/memo/${studentId}`, {
            memoId:memo._id,
            memo:memo.memo
        })
        .then(({status, data})=>{
            if(data.success){
                iziToast.success({ title:"성공적으로 수정했습니다." })
                commit('updateMemo', {
                    studentId, memo:data.data
                })
            }
        }, e=>_)
    },
    deleteMemo({ commit, dispatch, state }, {studentId, memo}){
        return axios.delete(`/student/memo/${studentId}`, {
            params:{ memoId:memo._id }
        })
        .then(({status, data})=>{
            if(data.success){
                iziToast.success({ title:"성공적으로 삭제했습니다." })
                commit('deleteMemo', {
                    studentId, memoId:memo._id
                })
            }
        }, e=>_)
    }
}

const mutations = {
    list(state, list){
        state.list = list;
    },
    select(state, student){
        state.selectedStudent = student;
    },
    add(state, student){
        state.list.push(student);
    },
    delete(state, studentId){
        let idx = state.list.findIndex((student)=>student._id == studentId);
        if(idx !== -1){ 
            state.list.splice(idx, 1);
            if(studentId == state.selectedStudent._id)
                state.selectedStudent = initialStudent();
        }
    },
    update(state, {studentId, student}){
        let idx = state.list.findIndex((student)=>student._id == studentId);
        if(idx !== -1){ 
            state.list.splice(idx, 1, student);
            if(student._id == state.selectedStudent._id)
                state.selectedStudent = student;
        }
    },
    newStudent(state){
        state.selectedStudent = initialStudent();
    },
    selectParent(state, parentId){
        state.selectedStudent.parentId = parentId;
    },
    addMemo(state, {studentId, memo}){
        if(state.selectedStudent._id == studentId){
            state.selectedStudent.memos.push(memo);
        } else{
            let idx = state.list.findIndex(student => student._id === studentId);
            if(idx !== -1) state.list[idx].memos.push(memo);
        }
    },
    updateMemo(state, {studentId, memo}){
        if(state.selectedStudent._id == studentId){
            let idx = state.selectedStudent.memos.findIndex(_memo => _memo._id == memo._id);
            if(idx !== -1) state.selectedStudent.memos.splice(idx, 1, memo);
        } else{
            let idx = state.list.findIndex(student => student._id === studentId);
            if(idx !== -1){
                let student = state.list[idx];
                let idx2 = student.memos.findIndex(_memo => _memo._id == memo._id);
                if(idx2 !== -1) student.memos.splice(idx, 1, memo);
            }
        }
    },
    deleteMemo(state, {studentId, memoId}){
        if(state.selectedStudent._id == studentId){
            let idx = state.selectedStudent.memos.findIndex(_memo => _memo._id == memoId);
            if(idx !== -1) state.selectedStudent.memos.splice(idx, 1);
        } else{
            let idx = state.list.findIndex(student => student._id === studentId);
            if(idx !== -1){
                let student = state.list[idx];
                let idx2 = student.memos.findIndex(_memo => _memo._id == memoId);
                if(idx2 !== -1) student.memos.splice(idx, 1);
            }
        }
    }
}

export default {
    namespaced: true,
    state : initialState,
    getters,
    actions,
    mutations
}