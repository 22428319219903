import axios from '@/js/axiosWrapper';

const initialState = () => ({
    privileages:[],
    teacherId:'',
    username:''
})

const getters = {
    isLogedIn(state){
        return state.teacherId !== '';
    },
    isAdmin(state){
        return state.privileages.includes("admin");
    },
    isBusOnly(state){
        return state.privileages.includes("busOnly");
    }
}

const actions = {
    login({ commit, state, dispatch }, { username, password }){
        return axios.post("/auth/login", {
            username,
            password
        }, { handlerDisabled:true })
        .then(({ status, data })=>{
            if(data.success)
                return dispatch('check');
            throw Error('로그인 실패');
        })
    },
    logout({ commit, state }){
        return axios.post("/auth/logout", {})
        .then(({ status, data })=>{
            commit('reset');
        })
    },
    check({ commit, state }){
        return axios.get("/auth/check", { handlerDisabled:true })
        .then(({status, data})=>{
            commit('login', data.data);
        })
    }

}

const mutations = {
    login(state, {teacherId, privilege, username}){
        state.username = username;
        state.teacherId = teacherId;
        state.privileages = privilege.join(',');
    },
    reset(state){
        let s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    }
}

export default {
    namespaced: true,
    state : initialState,
    getters,
    actions,
    mutations
}