import iziToast from 'izitoast';
import axios from '../../js/axiosWrapper';
import moment from 'moment-timezone';

const initialState = () => ({
    results: {
        rideOn:{},
        rideOff:{}
    },
    courses: [],
    logs: [],
    recentLogs: []
})

const getters = {
    
}

const actions = {
    getResult({ commit }, { date }){
        return axios.get(`/bus/result`,{
            params:{ date: new Date(date) }
        })
        .then(({status, data})=>{
            if(data.success){
                commit('results', data.data);
            }
        });
    },
    getLogs({ commit }, { date }){
        return axios.get(`/bus/logs`,{
            params:{ date: new Date(date) }
        })
        .then(({status, data})=>{
            if(data.success){
                commit('logs', data.data);
            }
        });
    },
    getRecentLogs({ commit }){
        return axios.get(`/bus/recentLogs`)
        .then(({status, data})=>{
            if(data.success){
                commit('recentLogs', data.data);
            }
        });
    },
    getCourseList({ commit, rootState }, { date }){
        let teacherId = rootState.user.teacherId;
        if(!teacherId) return Promise.reject(new Error('로그인해주세요'));

        let startTime = moment(date).set({
            hour:0,
            minute:0
        });
        let endTime = moment(date).set({
            hour:23,
            minute:59
        });

        return axios.get(`/bus/course/list`,{
            params:{fromDate:startTime.toDate(), toDate:endTime.toDate(), count:1000}
        })
        .then(({status, data})=>{
            if(data.success){
                commit('courses', data.data);
            }
        });
    },
    update({ commit }, { studentId, courseId, type, status, location, changeType }){
        if(location){
            return axios.put(`/bus/location/${courseId}/${studentId}`, { location })
            .then(({data})=>{
                if(data.success){
                    commit('update', { studentId, courseId, type, status, location })
                    iziToast.success({ title:"성공적으로 변경하였습니다.", timeout: 1000 });
                } else{
                    iziToast.error({ title:"변경에 실패했습니다." });
                    throw new Error("차량 변경에 실패했습니다.");
                }
            })
        } else{

            let url;
            if(changeType == 'once')
                url = `/bus/${courseId}/${studentId}`;
            else
                url = `/bus/after/${courseId}/${studentId}`;

            return axios.put(url, { type, status })
            .then(({data})=>{
                if(data.success){
                    commit('update', { studentId, courseId, type, status, location })
                    iziToast.success({ title:"성공적으로 변경하였습니다.", timeout: 1000 })
                } else{
                    iziToast.error({ title:"변경에 실패했습니다." })
                    throw new Error("차량 변경에 실패했습니다.");
                }
            })
        }

    }
}

const mutations = {
    courses(state, courses){
        state.courses = courses;
    },
    results(state, results){
        state.results = results;
    },
    update(state, { courseId, studentId, type, status, location }){
        if(location){
            for(let i=0; i<state.courses.length; i++){
                let course = state.courses[i];
                if(course._id == courseId){
                    let attendance = course.attendances.find(attendance => attendance.studentId == studentId);
                    if(attendance){
                        attendance.busLocation = location;
                    }
                }
            }
        } else{
            for(let i=0; i<state.courses.length; i++){
                let course = state.courses[i];
                if(course._id == courseId){
                    let attendance = course.attendances.find(attendance => attendance.studentId == studentId);
                    if(attendance){
                        attendance.busRide[type] = status;
                    }
                }
            }
        }
    },
    logs(state, logs){
        state.logs = logs;
    },
    recentLogs(state, recentLogs){
        state.recentLogs = recentLogs;
    }
}

export default {
    namespaced: true,
    state : initialState,
    getters,
    actions,
    mutations
}