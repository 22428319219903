import axios from '../../js/axiosWrapper';

const initialTeacher = () => ({
    name:'',
    phoneNumber:'',
    _id:'',
});

const initialState = () => ({
    list:[],
    selectedId:""
});

const getters = {
    newTeacher(){
        return initialTeacher();
    },
    map(state){
        return state.list.reduce((obj, el) => {
            obj[el._id] = el.name;
            return obj;
        }, {})
    }
}

const actions = {
    getList({ commit }){
        return axios.get('/teacher/list')
        .then(({ data, status })=>{
            if(data.success){
                commit('list', data.data);
            } else{
                throw new Error('선생님 목록을 가져오는데 실패했습니다.');
            }
        })
    },
}

const mutations = {
    list(state, list){
        state.list = list;
    },
    select(state, teacherId){
        state.selectedId = teacherId;
    },
    add(state, teacher){
        state.list.push(teacher);
    },
    delete(state, teacherId){
        let idx = state.list.findIndex((teacher)=>teacher._id == teacherId);
        if(idx !== -1){
            state.list.splice(idx, 1);
        }
    },
    update(state, teacherId, teacher){
        let idx = state.list.findIndex((teacher)=>teacher._id == teacherId);
        if(idx !== -1){
            state.list.splice(idx, teacher);
        }
    }
}

export default {
    namespaced: true,
    state : initialState,
    getters,
    actions,
    mutations
}