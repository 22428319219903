import axios from '../../js/axiosWrapper';
import iziToast from 'izitoast';
import jsonMask from 'json-mask';

const Masks = {
    "add": ["title", "text", "courseName", "date", "importance", "studentId"].join(","),
    "update": ["title", "text", "courseName", "date", "importance"].join(","),
}

const initialState = () => ({
    studentConsults : [],
    recentConsults : [],
    teacherConsults : [],
    page : 1
})

const getters = {
    
}

const actions = {
    getStudentConsult({ commit, state }, studentId){
        commit('resetStudentConsult');
        
        return axios.get(`/consult/listOfStudent/${studentId}`)
        .then(({ data, status })=>{
            if(data.success){
                commit('studentConsult', data.data);
            } else{
                throw new Error('학생 상담목록을 가져오는데 실패했습니다.');
            }
        })
    },
    getRecentConsults({ commit, state }, { fromDate, toDate }){       
        return axios.get("/consult/list/",{
            params:{
                count:1000,
                page:1,
                fromDate:fromDate,
                toDate:toDate
            }
        })
        .then(({status, data})=>{
            if(data.success){
                commit('recentConsults', data.data);
            }
        });
    },
    getTeacherConsults({ commit, state, rootState }, { more }){
        if( more ) commit('nextPage');
        else commit('resetTeacherConsults');

        return axios.get(`/consult/listOfTeacher/${rootState.user.teacherId}`,{
            params:{
                count:100,
                page:state.page
            }
        })
        .then(({status, data})=>{
            if(data.success){
                commit('teacherConsults', data.data);
            }
        }, e=>_);
    },
    add({ commit }, consult){
        return axios.post("/consult", jsonMask(consult, Masks.add))
        .then(({status, data})=>{
            if(data.success){
                iziToast.success({
                    title:"성공적으로 추가하였습니다."
                })
                commit('add', data.data);
                return data.data;
            } else{
                throw new Error("추가에 실패했습니다.");
            }
        })
    },
    update({ commit }, consult){
        return axios.put(`/consult/${consult._id}`,{
            text:consult.text
        })
        .then(({status, data})=>{
            if(data.success){
                commit('update', consult);
                iziToast.success({
                    title:"성공적으로 수정했습니다"
                })
            } else{
                throw new Error("수정에 실패했습니다.");
            }
        })
    },
    delete({ commit }, consultId){
        return axios.delete(`/consult/${consultId}`)
        .then(({status, data})=>{
            if(data.success){
                iziToast.success({
                    title:"성공적으로 삭제했습니다"
                })
                commit('delete', consultId);
            } else{
                throw new Error("삭제에 실패했습니다.");
            }
        })
    }
}

const mutations = {
    resetStudentConsult(state){
        state.studentConsults = [];
    },
    studentConsult(state, consults){
        state.studentConsults.push(...consults);
    },
    recentConsults(state, consults){
        state.recentConsults = [...consults];
    },
    resetTeacherConsults(state){
        state.teacherConsults = [];
    },
    teacherConsults(state, consults){
        state.teacherConsults.push(...consults);
        state.page = 1;
    },
    nextPage(state){
        state.page ++;
    },

    add(state, consult){
        state.teacherConsults.push(consult);
        state.recentConsults.push(consult);
        state.studentConsults.push(consult);

        state.teacherConsults.sort((a, b) => a.date == b.date ? 0 : (a.date > b.date ? -1 : 1));
        state.recentConsults.sort((a, b) => a.date == b.date ? 0 : (a.date > b.date ? -1 : 1));
        state.studentConsults.sort((a, b) => a.date == b.date ? 0 : (a.date > b.date ? -1 : 1));
    },
    update(state, consult){
        let idx = state.teacherConsults.findIndex(el => el._id == consult._id);
        if(idx !== -1) state.teacherConsults.splice(idx, 1, consult);
        idx = state.recentConsults.findIndex(el => el._id == consult._id);
        if(idx !== -1) state.recentConsults.splice(idx, 1, consult);
        idx = state.studentConsults.findIndex(el => el._id == consult._id);
        if(idx !== -1) state.studentConsults.splice(idx, 1, consult);
    },
    delete(state, consultId){
        let idx = state.teacherConsults.findIndex(el => el._id == consultId);
        if(idx !== -1) state.teacherConsults.splice(idx, 1);
        idx = state.recentConsults.findIndex(el => el._id == consultId);
        if(idx !== -1) state.recentConsults.splice(idx, 1);
        idx = state.studentConsults.findIndex(el => el._id == consultId);
        if(idx !== -1) state.studentConsults.splice(idx, 1);
    }
}

export default {
    namespaced: true,
    state : initialState,
    getters,
    actions,
    mutations
}