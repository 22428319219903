import './helper.js';
import './common.js';
import Vue from 'vue';
import router from './router';
import store from '@/store/index';
import navBar from '@/components/common/navbar.vue';

router.beforeEach((to, from, next) => {
    if(store.getters['user/isLogedIn'] == false){
        store.dispatch('user/check')
        .then(()=>{
            app.$toast.info({
                title:`${store.state.user.username} 인증 되었습니다`
            })
            next();
        }, (err)=>{
            app.$toast.error({
                title:'로그인을 해주세요'
            });
            next(false);
        })
    } else{
        next();
    }
})

router.beforeEach((to, from, next) => {
    if(store.getters['user/isBusOnly'] == true){
        console.log(to);
        if(to.name !== "bus"){
            next("bus")
        } else{
            next();
        }
    } else{
        next();
    }
})

const app = new Vue({
    el:"#app",
    router,
    store,
    components:{
        navBar
    }
})