import axios from '../../js/axiosWrapper';
import iziToast from 'izitoast';

const initialState = () => ({
    imageList:[],
    list:[]
})

const getters = {
}

const actions = {
    getImageList({ commit }){
        return axios.get("/upload/list")
        .then(({data, status})=>{
            if(data.success){
                commit('imageList', data.data);
                app.images = data.data;
            }
        })
    },
    getList({ commit }){
        return axios.get("/file/list")
        .then(({data, status})=>{
            if(data.success){
                commit('list', data.data);
                app.images = data.data;
            }
        })
    },
    upload({ commit }, files){
        const formData = new FormData();
        for(var i=0; i<files.length; i++)
            formData.append("files", files[i]);

        return axios.post("/file/", formData, {
            headers:{
                'Content-Type':'multipart/form-data'
            }
        })
        .then(({data, status})=>{
            if(data.success){
                iziToast.success({title:`파일 ${data.data.length}개를 성공적으로 업로드했습니다.`});
                commit('addList', data.data);
            }
        })
    }
}

const mutations = {
    imageList(state, list){
        state.imageList = list;
    },
    list(state, list){
        state.list = list;
    },
    addList(state, list){
        state.list.splice(0, 0, ...list);
    }
}

export default {
    namespaced: true,
    state : initialState,
    getters,
    actions,
    mutations
}