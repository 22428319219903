import iziToast from 'izitoast';
import axios from '../../js/axiosWrapper';

const COUNT = 300;

const initialState = () => ({
    reservedList:[],
    list:[]
})

const getters = {
    map(state){
        return state.list.reduce((obj, el) => {
            obj[el._id] = el.name;
            return obj;
        }, {})
    },
    hasMore(state){
        return state.list.length !== 0 && 
                state.list.length % COUNT == 0;
    }
}

const actions = {
    getList({ commit, state }, { fromDate, toDate, more }){
        if( more ) commit('nextPage');
        else commit('resetList');

        return axios.get('/message/list', {
            params:{
                fromDate,
                toDate,
                count:COUNT,
                page:state.page
            }
        })
        .then(({ data, status })=>{
            if(data.success){
                commit('list', data.data);
            } else{
                throw new Error('문자 목록을 가져오는데 실패했습니다.');
            }
        })
    },
    getReserveList({ commit }){
        return axios.get("/message/reservation/list")
        .then(({data, status})=>{
            if(data.success){
                commit('reservedList', data.data);
            }
        })
    },
    send({ commit, dispatch }, { message, parentIds, isReservation, reservationTime, imageSeq }){
        return axios.post('/message', {
            message:message,
            parentIds:parentIds,
            reservationTime:reservationTime,
            imageSeq:imageSeq
        })
        .then(({status, data})=>{
            if(data.success){
                iziToast.success({
                    title:"성공적으로 전송했습니다."
                });
                if(isReservation){
                    return dispatch('getReserveList');
                }
            }
        })
    },
    cancel({ commit }, messageId){
        return axios.delete(`/message/reservation/${messageId}`)
        .then(({status, data})=>{
            if(data.success){
                iziToast.success({
                    title:"성공적으로 취소했습니다."
                })
                commit("cancel", messageId);
            }
        })
    }
}

const mutations = {
    reservedList(state, list){
        state.reservedList = list;
    },
    cancel(state, messageId){
        let idx = state.reservedList.findIndex(el => el._id == messageId);
        if(idx !== -1) state.reservedList.splice(idx, 1);
        
        idx = state.list.findIndex(el => el._id == messageId);
        if(idx !== -1) state.list.splice(idx, 1);
    },
    nextPage(state){
        state.page ++;
    },
    resetList(state){
        state.page = 1;
        state.list = [];
    },
    list(state, list){
        state.list.push(...list);
    }
}

export default {
    namespaced: true,
    state : initialState,
    getters,
    actions,
    mutations
}