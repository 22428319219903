import jQuery from 'jquery';
import 'bootstrap';
import axios from './axiosWrapper';
import Vue from "vue";
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from "izitoast";
import Swal from 'sweetalert2';
import VueRouter from 'vue-router';
import moment from 'moment-timezone';
import Vuelidate from 'vuelidate';
import cloneDeep from 'lodash/cloneDeep';

moment.tz.setDefault("Asia/Seoul");
moment.updateLocale('en', {
    relativeTime : {
        future: "%s 후",
        past:   "%s 전",
        s  : '몇초',
        ss : '%d 초',
        m:  "일분",
        mm: "%d 분",
        h:  "한시간",
        hh: "%d 시간",
        d:  "하루",
        dd: "%d 일",
        M:  "한달",
        MM: "%d 달",
        y:  "일년",
        yy: "%d 년"
    },
    weekdays : [
        "일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"
    ]
});

window.$ = window.jQuery = jQuery;

Vue.filter("numberFormat", function(value){
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
})

Vue.use(VueRouter);
Vue.use(Vuelidate);

Vue.use({
    install(Vue, options) {
        Vue.prototype.$moment = moment;
        Vue.prototype.$toast = iziToast;
        Vue.prototype.$swal = Swal;
        Vue.prototype.$question = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success px-4 mx-2',
              cancelButton: 'btn btn-danger px-4 mx-2'
            },
            buttonsStyling: false,
            focusConfirm:true,
            showCancelButton:true,
            type:"question"
        })
        Vue.prototype.$ajax = axios;
        Vue.prototype.$_ = { cloneDeep };
    }
})

// window.onerror = function (msg, url, lineNo, columnNo, error) {
//     iziToast.error({
//         title: '내부 에러 발생',
//         message: msg
//     });
//     return false;
// }

Number.prototype.format = function () {
    return this.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};