import Vue from 'vue';
import Vuex from 'vuex';
import students from './modules/students';
import user from './modules/user';
import attendances from './modules/attendances';
import consults from './modules/consults';
import courses from './modules/courses';
import evaluations from './modules/evaluations';
import parents from './modules/parents';
import payments from './modules/payments';
import teachers from './modules/teachers';
import messages from './modules/messages';
import files from './modules/files';
import bus from './modules/bus';
import tuition from './modules/tuition';

import createLogger from 'vuex/dist/logger'

Vue.use(Vuex);

export default new Vuex.Store({
    modules:{
        students,
        user,
        attendances,
        consults,
        courses,
        evaluations,
        parents,
        payments,
        teachers,
        messages,
        files,
        bus,
        tuition
    },
    strict: process.env.NODE_ENV == 'development',
    plugins: process.env.NODE_ENV == 'development' ? 
        [createLogger()] :
        []
})