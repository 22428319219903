import axios from '../../js/axiosWrapper';
import iziToast from 'izitoast';

const initialState = () => ({
    studentEvaluations : [],
    categoryInfo : [],
    studentId: "",
    templates: [],
    questions: []
})

const getters = {
    
}

const actions = {
    getStudentEvaluation({ commit, state }, { studentId, more, fromDate, toDate }){
        if(state.studentId !== studentId){
            commit('resetStudentEvaluation');
            commit('studentId', studentId);
        } else{
            if(more) commit('nextPage');
            else commit('resetStudentEvaluation');
        }

        
        return axios.get(`/evaluation/list`,{
            params:{
                studentId:studentId,
                fromDate:fromDate.toDate(),
                toDate:toDate.toDate()
            }
        })
        .then(({ data, status })=>{
            if(data.success){
                commit('studentEvaluation', data.data);
            } else{
                throw new Error('학생 평가목록을 가져오는데 실패했습니다.');
            }
        })
    },
    getCategoryInfo({ commit }){
        return axios.get("/evaluation/templates")
        .then(({ data, status })=>{
            if(data.success){
                commit('categoryInfo', data.data);
            }
        })
    },
    getTemplates({ commit }){
        return axios.get("/evaluation/templates")
        .then(({status, data})=>{
            if(data.success){
                commit('templates', data.data);
            }
        })
    },
    getQuestions({ commit }){
        return axios.get("/evaluation/questions")
        .then(({status, data})=>{
            if(data.success){
                commit('questions', data.data);
            }
        })
    },
    evaluate({ commit }, evaluation){
        return axios.post('/evaluation', evaluation)
        .then(({status, data})=>{
            if(data.success){
                iziToast.success({
                    title:"성공적으로 평가했습니다."
                });
            }
        })
    }
}

const mutations = {
    resetStudentEvaluation(state){
        state.studentEvaluations = [];
    },
    studentEvaluation(state, evaluations){
        state.studentEvaluations.push(...evaluations);
    },
    studentId(state, studentId){
        state.studentId = studentId;
    },
    categoryInfo(state, categoryInfo){
        state.categoryInfo = categoryInfo;
    },
    templates(state, templates){
        state.templates = templates;
    },
    questions(state, questions){
        state.questions = questions;
    }
}

export default {
    namespaced: true,
    state : initialState,
    getters,
    actions,
    mutations
}