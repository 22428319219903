import Vue from 'vue'
import iziToast from 'izitoast';
import axios from '../../js/axiosWrapper';
import jsonMask from 'json-mask';

const initialState = () => ({
    reports: {},
    unpaid: [],
    linkedList: []
})

const getters = {
    parentReports : (state) => (parentId) => state.reports[parentId] || []
}

const actions = {
    getUnpaidList({ commit }){
        return axios.get("/tuition/list/unpaid")
        .then(({ status, data : { success, data }})=>{
            if(success){
                commit("unpaid", data);
                return data;
            }
        })
    },

    getPaymentLinkedReports({ commit }, { parentId }){
        return axios.get(`/tuition/linkedList/${parentId}`)
        .then(({status, data : { success, data }})=>{
            if(success){
                commit("linked", data);
                return data;
            }
        });
    },

    getReports({ commit }, { parentId }){
        return axios.get(`/tuition/list/${parentId}`)
        .then(({status, data : { success, data }})=>{
            if(success){
                commit('reports', { parentId, reports: data });
            }
        });
    },
    /**
     * @deprecated 
     * @param {string} date - YYYY-MM 형식 
     * */
    getReport({ commit }, { parentId, date }){
        return axios.get(`/tuition/${parentId}`,{
            params:{ date: date }
        })
        .then(({status, data : { success, data }})=>{
            if(success){
                commit('report', { parentId, report: data });
            }
        });
    },

    /**
     * 
     * @param {object} data
     * @param {string} data.reportId
     * @param {string} data.studentId
     * @param {string} data.courseInfoId
     * @param {number} [data.discountRate]
     * @param {number} [data.discountAmount]
     * @param {number} [data.courseCount]
     * @param {string} [data.memo]
     */
    updateReport({ commit, dispatch }, data){
        return axios.put(`/tuition/${data.reportId}`, jsonMask(data, [
            "studentId", "courseInfoId", "discountRate", "discountAmount", "courseCount", "memo"
        ].join(",")))
        .then(({status, data : { success, data }})=>{
            if(success){
                dispatch("getUnpaidList");
                commit('report', { report: data });
                iziToast.success({
                    title:"성공적으로 수정했습니다."
                });
            }
        });
    },

    /**
     * @param {object} data
     * @param {string} data.reportId
     * @param {string} [data.memo]
     */
    updatePayment({ commit, dispatch }, data){
        return axios.post(`/tuition/pay/${data.reportId}`, jsonMask(data, [
            "paidAmount", "paidType", "paidDate", "memo"
        ].join(",")))
        .then(({status, data : { success, data }})=>{
            if(success){
                dispatch("getUnpaidList");
                commit('report', { report: data });
                iziToast.success({
                    title:"성공적으로 수정했습니다."
                });
            }
        });
    },

    /**
     * @param {object} data
     * @param {string} data.parentId
     */
    requestSMS({ commit }, data){
        return axios.post(`/tuition/request/sms`, {
            parentId: data.parentId
        })
        .then(({status, data : { success, data }})=>{
            if(success){
                iziToast.success({
                    title:"성공적으로 전송했습니다."
                });
            }
        });
    }
}

const mutations = {
    linked(state, list){
        state.linkedList = list;
    },
    unpaid(state, list){
        state.unpaid = list;
    },
    reports(state, { parentId, reports }){
        Vue.set(state.reports, parentId, reports);
    },
    report(state, { report }){
        let reports = state.reports[report.parentId];
        if(!reports){
            Vue.set(state.reports, report.parentId, [ report ]);
        } else{
            let idx = reports.findIndex(el => el._id == report._id);
            if(idx !== -1){
                reports.splice(idx, 1, report);
            } else{
                reports.push(report);
            }
            // 레포트들의 baseTuition, accumulatedTuition 재정립
            reports.sort((a, b) => a.date < b.date ? 1 : -1);
            for(let i=reports.length - 2; i>=0; i--){
                reports[i].baseTuition = reports[i + 1].accumulatedTuition;
                reports[i].accumulatedTuition = reports[i].baseTuition + reports[i].finalTuition;
                reports.splice(i, 1, reports[i]);
            }
        }
    },
    reset(state){
        state.reports = {};
        state.unpaid = [];
    }
}

export default {
    namespaced: true,
    state : initialState,
    getters,
    actions,
    mutations
}